.homeParent{
  width: auto;
}


.newsBlock{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, auto));
  row-gap: 10px;
  column-gap: 10px;

}

.latest {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }
  
  .latestTitle {
    font-weight: bold;
    color: var(--themeColor);
    font-size: 24px;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-transform: uppercase;
  }
  
  .latestsubTitle {
    margin-top: 20px;
    font-weight: bold;
    color: #08203d;
    line-height: 55px;
    font-size: 45px;
  }



.panImageBkg{


background-image: url("trident3.png");
background-size: cover;
background-repeat: no-repeat;
height: 1000px;
}

















  @media (max-width: 1300px) {
 
    .latestTitle {
      font-size: 20px;
    }
  
    .latestsubTitle {
      margin-top: 10px;
      font-size: 30px;
    }
  }
  
