
.onlineRegistrationHome{
width: 100%;
height: 100%;
margin: auto;
border-style: none;
}


.title{
width: 100%;
padding: 10px;
font-size: 25px;
font-weight: bold;
display: flex;
justify-content: center;
border-style: none;
background-color: white;
}



.title span{
margin-top: 10px;
}

.FormMainDiv{
display: flex;
max-width: 1000px;
border-style: solid;
border-width: 1px;
margin: auto;
color: lightgrey;
flex-direction: column;
margin-top: 40px;
border-radius: 10px;
padding-bottom: 100px;
}

.FormMainDiv_inner{
width: 90%;
margin:auto;
margin-top:40px;
margin-bottom: 40px;

}



.titleBlock{

border-style: none;
color: black;
width: 100%;
display: flex;
justify-content: center;

}

.logo{
height: 100%;
aspect-ratio: 1/1;
border-style: none;
}

.imageLogo{
width: 100%;
height: 100%;
}


.InstTitle{
border-style: none;
margin-left: 40px;
}

.mainTitle{
border-style: none;
font-size: 30px;
}

.tagTitle{

border-style: none;
}



.descriptionRole{

margin: auto;
color:   #4285F4;
border-style: none;
display: flex;
justify-content: center;
width: 100%;
}


.textContent{
margin: auto;
border-style: none;
display: flex;
justify-content: center;
width: 100%;
color: black;
margin-top: 50px;
}


.firstTextDiv{
display: flex;
border-style: none;
align-items: center;
color: black;
}

.addressDefault{

margin-left: 120px;

}



.input_field{

width: 100%;
max-width: 500px;
border-style: solid;
margin: 10px;
height: 30px;
border-radius:5px;
border-width: 1px;
border-color: lightgrey;
}



.input_fieldView{

width: 100%;
max-width: 500px;
border-style: none;
margin: 10px;
height: 30px;
border-radius:5px;
border-width: 1px;
border-color: lightgrey;
}










.fieldTitleDiv{

width: 100px;
}




.photoPreView{


margin-left: 120px;
border-radius: 10px;

}


.submitButtonDiv{

width: 100%;
border-style: none;
margin-top: 20px;
height: 40px;
margin-top: 50px;
display: flex;
justify-content: center;
}

.submit_button{

width: 200px;
border-style: none;
background-color: var(--themeColor);
color: white;
border-radius: 10px;
cursor: pointer;
}

.successMessage{

width: 100%;
height: 50px;
margin-top: 40px;
color: var(--themeColor);
margin-bottom:30px;
}

.redirecButton{

background-color: white;
border-style: none;
text-decoration: underline;
color: blue;
cursor: pointer;
font-size: 18px;
}

.redirecButton:hover{

color: green;
}




.ViewAllMembersParent{

height: 100vh;
width: 100vw;
border-style: solid;
display: grid;
grid-template-columns: repeat(auto-fit, minmax( 300px, 500px) );
grid-auto-rows: 800px;
border-style: none;
grid-column-gap: 50px;
grid-row-gap: 50px;

}

.memberphotoView{

width:150px;
height: 200px;

}


.viewMemberPageHeader{

height: 100px;
width: 100%;
border-style: none;
background-color: lightgrey;
display: flex;
justify-content: center;
align-items: center;
}




@media only screen and (max-width: 600px) {

.FormMainDiv{
}

.mainTitle{

font-size: 18px;	
}

.photoPreView{
margin-left: 60px;

}


.addressDefault{

margin-left: 70px;

}





}




.submit_button_success{

height: 40px;
color:white;
background: black;
border-style: none;
border-radius: 10px;
width: 200px;
margin-top: 30px;
}



